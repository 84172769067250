import { i18nResource } from "../../i18nResource.interface";

const section5: i18nResource.Section5 = {
    title: "ROADMAP",

    timeline1_description1: "Launch PFP-NFT",
    timeline1_description2: "Collaborations with Major NFT Projects",
    timeline1_description3: "Pre-alpha Reveal of the Metaverse",

    timeline2_description1: "1st P2E Game : Cyber Core",
    timeline2_description2: "Playable Collaboration NFTs in the Metaverse",
    timeline2_description3: "Improved Metaverse",

    timeline3_description1: "",
    timeline3_description2: "2nd P2E Game : Zombie Core",
    timeline3_description3: "Multiple Playgrounds in the Metaverse",

    timeline4_description1: "",
    timeline4_description2: "Arena Mode (PVP)",
    timeline4_description3: "3rd P2E Game",

    timeline5_description1: "AR / VR Experience",
    timeline5_description2: "Advanced gameplay",
};

export default section5;
