import styled from "@emotion/styled";
import { ScreenType } from "../../themes/Limit";

export const SectionRoot = styled("article")`
    display: flex;
    flex-direction: column;

    position: relative;
    height: calc(100vh - 64px);

    @media (max-width: ${ScreenType.medium}px) {
        height: calc(100vh - 54px);
    }

    @media (max-height: 700px) {
        height: 700px;
    }
`;
