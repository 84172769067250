import { i18nResource } from "../../i18nResource.interface";

const section1: i18nResource.Section1 = {
    descriptionTitle: "METAVERSE OF P2E GAMES",
    descriptionBody1:
        "Meta Adventure is an online multiplayer Play-To-Earn platform featuring exciting games.",
    descriptionBody2:
        "Our Metaverse is populated by NFTs you can collect and use across different worlds",
    descriptionBody3:
        "which can be customized to your needs, in a series of games to come in PC, Mobile and VR.",
    buttonText: "WHITE PAPER",
};

export default section1;
