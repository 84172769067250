import styled from "@emotion/styled";
import {
    LIGHT_PRIMARY_BRIGHT_COLOR,
    LIGHT_SECONDARY_COLOR,
} from "../../themes/Color";

export type HighlightTypoProps = {
    variant: "primary" | "secondary";
};

export const HighlightTypo = styled("span")<HighlightTypoProps>`
    color: ${(props) =>
        props.variant === "primary"
            ? LIGHT_PRIMARY_BRIGHT_COLOR
            : LIGHT_SECONDARY_COLOR};
`;
