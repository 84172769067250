import { useState } from "react";

export const useLangPopup = () => {
    const langs = ["EN"];
    const [lang, setlang] = useState(langs[0]);
    const handleChangeLang = (lang: string) => {
        setlang(lang);
    };

    return {
        langs,
        lang,
        handleChangeLang,
    };
};
