import { css } from "@emotion/react";
import { ScreenType } from "../Limit";

export const DescriptionBodyStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0.9;

    @media (max-width: ${ScreenType.medium}px) {
        gap: 12px;
    }
`;
