import styled from "@emotion/styled";
import { Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Image } from "../../../components/media/images/Image";
import { HighlightTypo } from "../../../components/typography/HighlightTypo";
import { ScreenType } from "../../../themes/Limit";
import { useSection4 } from "./useSection4";

export const Section4 = () => {
    const { t } = useTranslation();
    const { cardItems } = useSection4();

    return (
        <Root>
            <TitleTypo variant="h3">
                <span>{t("section4:title1_1")}</span>
                <HighlightTypo variant="secondary">
                    {t("section4:title1_2")}
                </HighlightTypo>
                <HighlightTypo variant="primary">
                    {t("section4:title1_3")}
                </HighlightTypo>
            </TitleTypo>

            <GridWrapper>
                <Grid container spacing={2}>
                    {cardItems.map((it, index) => (
                        <Grid
                            key={it}
                            item
                            xl={2.3}
                            lg={3}
                            md={4}
                            sm={6}
                            xs={6}
                        >
                            <CardWrapper>
                                <VideoCard src={it} autoPlay muted loop />
                            </CardWrapper>
                        </Grid>
                    ))}
                </Grid>
            </GridWrapper>
        </Root>
    );
};

// Root
const Root = styled("article")`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #000000;
    align-items: center;
    justify-content: center;
    padding: 80px 24px;

    @media (max-width: ${ScreenType.tablet}px) {
        height: fit-content;
    }
`;

// Typo
const TitleTypo = styled(Typography)`
    text-align: center;

    @media (max-width: ${ScreenType.medium}px) {
        font-size: 32px;
    }
`;

// grid
const GridWrapper = styled(Stack)`
    width: 100%;
    max-width: 1024px;
    margin-top: 48px;
`;

// card
const CardWrapper = styled(Stack)`
    position: relative;
    width: 100%;
    aspect-ratio: 2/3;

    margin-top: 12px;

    @media (max-width: ${ScreenType.tablet}px) {
        margin-top: 16px;
    }
`;

const VideoCard = styled("video")`
    object-fit: cover;
    height: 100%;
`;