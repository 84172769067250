import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { ReactNode } from "react";

export type MenuPopupProps = {
    menus: string[];
    value: ReactNode;
    onClick: (v: string) => void;
};

export const MenuPopup = (props: MenuPopupProps) => {
    const { menus, value, onClick } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (v: string) => {
        setAnchorEl(null);
        onClick(v);
    };

    const endIcon = open ? <ExpandLessRounded /> : <ExpandMoreRounded />;

    return (
        <Stack>
            <Stack color={"#fff"}>
                <Button
                    color="inherit"
                    endIcon={endIcon}
                    sx={{ width: "fit-content" }}
                    onClick={handleButtonClick}
                >
                    {value}
                </Button>
            </Stack>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {menus.map((it) => (
                    <MenuItem key={it} onClick={() => handleMenuClick(it)}>
                        <Typography color={pointColor}>{it}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Stack>
    );
};

const pointColor = "#111220";
