import { i18nResource } from "../../i18nResource.interface";

const section2: i18nResource.Section2 = {
    title1: "META ADVENTURE ",
    title2: "NFTS",
    title3: "",
    descriptionBody1_1: "The First Arrival NFT collection consists of ",
    descriptionBody1_2: "10,000 unique NFTs ",
    descriptionBody1_3: "playable in META ADVENTURE.",
    descriptionBody2_1: "All First Arrival NFTs grant ",
    descriptionBody2_2: "exclusive perks ",
    descriptionBody2_3:
        "that will maximize your earning potential in our Play-to-Earn economy.",
};

export default section2;
