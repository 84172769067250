import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Image } from "../../../components/media/images/Image";
import { ScreenType } from "../../../themes/Limit";
import { TimeLineItem } from "./timeline/TimeLineItem";

export const Section5 = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <TitleTypo variant="h4">{t("section5:title")}</TitleTypo>

            <Stack width={"100%"} mt={"48px"} direction={"row"}>
                <Stack
                    position={"relative"}
                    width={"100%"}
                    alignItems={"center"}
                >
                    <TimeLineBarContainer>
                        <TimeLineBarWrapper>
                            <Image
                                src={`${process.env.PUBLIC_URL}/images/section5/timeline_bar.png`}
                                alt="timeline_bar"
                            />
                        </TimeLineBarWrapper>
                    </TimeLineBarContainer>

                    <TimeLienItemContainer>
                        <TimeLineItem
                            year="2022"
                            title="Q1 2022"
                            body={[
                                t("section5:timeline1_description1"),
                                t("section5:timeline1_description2"),
                                t("section5:timeline1_description3"),
                            ]}
                            marginBottom="80px"
                        />
                        <TimeLineItem
                            title="Q2 2022"
                            body={[
                                t("section5:timeline2_description1"),
                                t("section5:timeline2_description2"),
                                t("section5:timeline2_description3"),
                            ]}
                            marginBottom="80px"
                        />
                        <TimeLineItem
                            title="Q3 2022"
                            body={[
                                t("section5:timeline3_description1"),
                                t("section5:timeline3_description2"),
                                t("section5:timeline3_description3"),
                            ]}
                        />
                        <TimeLineItem
                            title="Q4 2022"
                            body={[
                                t("section5:timeline4_description1"),
                                t("section5:timeline4_description2"),
                                t("section5:timeline4_description3"),
                            ]}
                            marginBottom="120px"
                        />
                        <TimeLineItem
                            year="2023"
                            title="Q1 2023"
                            body={[
                                t("section5:timeline5_description1"),
                                t("section5:timeline5_description2"),
                            ]}
                        />
                    </TimeLienItemContainer>
                </Stack>
            </Stack>
        </Root>
    );
};

//

const Root = styled(Stack)`
    position: relative;
    height: 1200px;

    align-items: center;
    padding: 80px 24px;
    background: linear-gradient(#2c0423, #10050c);
`;

// Typo
const TitleTypo = styled(Typography)`
    text-align: center;

    @media (max-width: ${ScreenType.medium}px) {
        font-size: 32px;
    }
`;

// time line
const TimeLineBarContainer = styled(Stack)`
    position: absolute;
    top: 6px;
    width: 100%;
    align-items: center;
`;

const TimeLineBarWrapper = styled(Stack)`
    width: 8px;
    height: 960px;
    margin-right: 220px;
`;

const TimeLienItemContainer = styled(Stack)`
    width: 100%;
    align-items: center;
    margin-left: 70px;
    z-index: 2;

    @media (max-width: ${ScreenType.medium}px) {
        margin-left: 14px;
    }
`;
