import { useCustomMediaQuery } from "../../../themes/UseCustomMediaQuery";

export const useSection4 = () => {
    const { isTablet, isLarge, isMedium } = useCustomMediaQuery();

    const cardItems = [
        `${process.env.PUBLIC_URL}/images/section4/card-1.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-8.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-2.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-5.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-4.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-6.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-3.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-7.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-0.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-9.mp4`,
    ];

    const getResponsiveItemCount = () => {
        if (isMedium) {
            return 8;
        }

        if (isLarge) {
            return 9;
        }

        if (isTablet) {
            return 12;
        }

        return cardItems.length;
    };

    const slicedItems = cardItems.slice(0, getResponsiveItemCount());

    return {
        cardItems: slicedItems,
    };
};
