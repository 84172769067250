import { createRef } from "react";

export type ScrollRefOption = {
    marginTop?: number;
};

export const useScrollRef = (refCount: number, options: ScrollRefOption) => {
    const { marginTop = 0 } = options;

    const refList = Array.from({ length: refCount }, () =>
        createRef<HTMLDivElement>()
    );

    const scrollTo = (index: number) => {
        const isRefNotNull = !!refList[index]?.current;

        if (isRefNotNull) {
            const refOffsetTop = refList[index]?.current?.offsetTop ?? 0;
            window.scrollTo({
                top: refOffsetTop - marginTop,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    return {
        refList,
        scrollTo,
    };
};
