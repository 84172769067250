import { useTranslation } from "react-i18next";

export const useGNB = () => {
    const { t } = useTranslation();

    const menus = [
        t("header:home"),
        t("header:nft"),
        t("header:metaVerse"),
        t("header:gallery"),
        t("header:roadmap"),
        t("header:team"),
        t("header:community"),
        // t("header:partners"),
    ];

    return {
        menus,
    };
};
