import { TypographyOptions } from "@mui/material/styles/createTypography";

const baseTypographyOptions: TypographyOptions = {
    fontFamily: "Russo One",
    allVariants: {
        color: "#fff",
    },
    h2: {
        fontSize: "54px",
    },
    h3: {
        fontSize: "44px",
    },
};

export const lightTypographyOptions: TypographyOptions = {
    ...baseTypographyOptions,
};

export const darkTypographyOptions: TypographyOptions = {
    ...baseTypographyOptions,
};
