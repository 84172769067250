export const links = [
    {
        name: "Whitepaper",
        path: "https://meta-adventure.gitbook.io/welcome-to-gitbook/fVsjxxYtStpR5X5lrHpj/introduction",
    },
    {
        name: "Discord",
        path: "https://discord.com/invite/metaadventure"
    },
    {
        name: "Twitter",
        path: "https://twitter.com/meta_adventure",
    },
];
