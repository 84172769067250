import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { kr, en } from "./locales/index";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: { en, "ko-KR": kr },
        lng: "en", // 기본 언어
        fallbackLng: "en", // detected lng이 불가능 할때 en을 사용하겠다.
        detection: { order: ["path", "navigator"] },
        keySeparator: false, // 'messages.welcome' 와 같은 키 형식의 form을 사용할지 여부

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
