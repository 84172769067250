import styled from "@emotion/styled";
import {
    Grid,
    GridProps,
    Stack,
    Typography,
    TypographyProps,
} from "@mui/material";
import { HighlightTypo } from "../../../components/typography/HighlightTypo";
import { DescriptionBodyStyle } from "../../../themes/global-styles/DescriptionBody.style";
import { ScreenType } from "../../../themes/Limit";
import { useSection6 } from "./useSection6";
import { Image } from "../../../components/media/images/Image";
import { useCustomMediaQuery } from "../../../themes/UseCustomMediaQuery";
import { useTranslation } from "react-i18next";

export const Section6 = () => {
    const { t } = useTranslation();
    const { teamItems } = useSection6();

    return (
        <Root>
            <TitleTypo variant="h4">{t("section6:title")}</TitleTypo>

            <GridWrapper>
                <GridContainer>
                    {teamItems.map((it, index) => (
                        <Grid
                            key={it.name}
                            item
                            xl={2.4}
                            lg={3}
                            md={4}
                            sm={6}
                            xs={6}
                        >
                            <Stack textAlign={"center"}>
                                <Stack>
                                    <Image src={it.img} alt={it.name} />
                                </Stack>

                                <Stack alignItems={"center"} mt={"24px"}>
                                    <NameTypo>{it.name}</NameTypo>
                                    <HighlightTypo variant="secondary">
                                        {it.position}
                                    </HighlightTypo>
                                </Stack>

                                <DescriptionBody>
                                    <Typography variant="body2">
                                        {it.description}
                                    </Typography>
                                </DescriptionBody>
                            </Stack>
                        </Grid>
                    ))}
                </GridContainer>
            </GridWrapper>
        </Root>
    );
};

//
const Root = styled(Stack)`
    background-color: #151a2f;
    align-items: center;
    padding: 80px 24px;
`;

// Typo
const TitleTypo = styled(Typography)`
    text-align: center;

    @media (max-width: ${ScreenType.medium}px) {
        font-size: 32px;
    }
`;

const NameTypo = (props: TypographyProps) => {
    const { isSmall } = useCustomMediaQuery();
    return <Typography variant={isSmall ? "h5" : "h4"} {...props} />;
};

const DescriptionBody = styled(Stack)`
    margin-top: 24px;
    ${DescriptionBodyStyle}
`;

// grid
const GridWrapper = styled(Stack)`
    width: 100%;
    max-width: 1440px;
    margin-top: 48px;
`;

const GridContainer = (props: GridProps) => {
    const { isSmall } = useCustomMediaQuery();
    const spacing = isSmall ? 4 : 6;

    return <Grid container spacing={spacing} {...props} />;
};
