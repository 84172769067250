import styled from "@emotion/styled";
import { LinkRounded } from "@mui/icons-material";
import { AppBar, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Image } from "../../../components/media/images/Image";
import { useCustomMediaQuery } from "../../../themes/UseCustomMediaQuery";
import { MenuPopup } from "./MenuPopup/MenuPopup";
import { useGNB } from "./useGNB";
import { useLangPopup } from "./useLangPopup";
import { useLinkPopup } from "./useLinkPopup";

const ToolbarBgColor = "#111220";

export type GlobalNavigationBarProps = {
    onMenuClick: (index: number) => void;
};

export const GlobalNavigationBar = (props: GlobalNavigationBarProps) => {
    const { menus } = useGNB();
    const { onMenuClick } = props;
    const onMoveClick = (v: string) => {
        onMenuClick(menus.findIndex((it) => it === v));
    };

    const { langs, lang, handleChangeLang } = useLangPopup();
    const { links, handleLinkClick } = useLinkPopup();

    const { isSmall, isTablet, isLaptop } = useCustomMediaQuery();

    return (
        <>
            <AppBar position="fixed">
                <Toolbar sx={{ bgcolor: ToolbarBgColor }}>
                    <Inner>
                        <LogoWrapper>
                            <Image
                                src={`${process.env.PUBLIC_URL}/images/common/logo_horizontal.png`}
                                alt="logo"
                            />
                        </LogoWrapper>

                        {!isTablet && (
                            <Stack
                                direction={"row"}
                                spacing={isLaptop ? 6 : 8}
                                justifySelf={"center"}
                            >
                                {menus.map((it, index) => (
                                    <Typography
                                        key={it}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => onMenuClick(index)}
                                    >
                                        {it}
                                    </Typography>
                                ))}
                            </Stack>
                        )}

                        <MenuPopupContainer>
                            {isTablet && !isSmall && (
                                <Stack mr={"24px"}>
                                    <MenuPopup
                                        menus={menus}
                                        onClick={onMoveClick}
                                        value={"MOVE"}
                                    />
                                </Stack>
                            )}

                            <MenuPopup
                                menus={links}
                                onClick={handleLinkClick}
                                value={<LinkRounded />}
                            />

                            <MenuPopup
                                menus={langs}
                                onClick={handleChangeLang}
                                value={lang}
                            />
                        </MenuPopupContainer>
                    </Inner>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    );
};

//

const Inner = styled(Stack)`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const LogoWrapper = styled(Stack)`
    width: 100%;
    max-width: 280px;
    height: auto;
    margin-bottom: 16px;
    flex-shrink: 1;
    margin-right: 24px;
`;

const MenuPopupContainer = styled(Stack)`
    width: 100%;
    max-width: 280px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 4;
    margin-left: 24px;
`;
