import { ThemeProvider } from "@mui/material";
import HomePage from "./pages/home";
import { defaultTheme } from "./ui/themes/CustomThemes";
import "./localization/i18n";

function App() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <HomePage />
        </ThemeProvider>
    );
}

export default App;
