import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SectionRoot } from "../../../components/base/SectionRoot";
import { Image } from "../../../components/media/images/Image";
import { HighlightTypo } from "../../../components/typography/HighlightTypo";
import { DescriptionBodyStyle } from "../../../themes/global-styles/DescriptionBody.style";
import { ScreenType } from "../../../themes/Limit";

export const Section2 = () => {
    const { t } = useTranslation();

    const cardImages = [
        `${process.env.PUBLIC_URL}/images/section4/card-1.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-3.mp4`,
        `${process.env.PUBLIC_URL}/images/section4/card-5.mp4`,
    ];

    return (
        <Root>
            <Inner>
                <TitleTypo variant="h3">
                    <span>{t("section2:title1")}</span>
                    <HighlightTypo variant="secondary">
                        {t("section2:title2")}
                    </HighlightTypo>
                    <HighlightTypo variant="primary">
                        {t("section2:title3")}
                    </HighlightTypo>
                </TitleTypo>

                <CardContainer>
                    {cardImages.map((it, index) => (
                        <CardImageWrapper key={it}>
                            <VideoCard src={it} autoPlay muted loop />
                        </CardImageWrapper>
                    ))}
                </CardContainer>

                <DescriptionBody>
                    <Typography variant="body2">
                        <span>{t("section2:descriptionBody1_1")}</span>
                        <HighlightTypo variant="primary">
                            {t("section2:descriptionBody1_2")}
                        </HighlightTypo>
                        <span>{t("section2:descriptionBody1_3")}</span>
                    </Typography>

                    <Typography variant="body2">
                        <span>{t("section2:descriptionBody2_1")}</span>
                        <HighlightTypo variant="secondary">
                            {t("section2:descriptionBody2_2")}
                        </HighlightTypo>
                        <span>{t("section2:descriptionBody2_3")}</span>
                    </Typography>
                </DescriptionBody>
            </Inner>
        </Root>
    );
};

// Root
const Root = styled(SectionRoot)`
    background-color: #000000;
`;

const Inner = styled(Stack)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 48px 24px;
`;

// Typo
const TitleTypo = styled(Typography)`
    text-align: center;

    @media (max-width: ${ScreenType.medium}px) {
        font-size: 32px;
    }
`;

const DescriptionBody = styled(Stack)`
    text-align: center;
    margin-top: 48px;
    ${DescriptionBodyStyle}
`;

// Card
const CardContainer = styled(Stack)`
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 48px;
    overflow: hidden;
    gap: 32px;

    @media (max-width: ${ScreenType.medium}px) {
        gap: 16px;
    }
`;

const CardImageWrapper = styled(Stack)`
    width: fit-content;
    max-width: 400px;
    height: 100%;
    max-height: 570px;
`;

const VideoCard = styled("video")`
    object-fit: cover;
    height: 100%;
`;