import { useMediaQuery } from "@mui/material";
import { ScreenType } from "./Limit";

export const useCustomMediaQuery = () => {
    const isSmall = useMediaQuery(`(max-width:${ScreenType.small}px)`);
    const isMedium = useMediaQuery(`(max-width:${ScreenType.medium}px)`);
    const isLarge = useMediaQuery(`(max-width:${ScreenType.large}px)`);
    const isTablet = useMediaQuery(`(max-width:${ScreenType.tablet}px)`);
    const isLaptop = useMediaQuery(`(max-width:${ScreenType.laptop}px)`);

    return {
        isSmall,
        isMedium,
        isLarge,
        isTablet,
        isLaptop,
    };
};
