export const useSection6 = () => {
    return {
        teamItems,
    };
};

//

const teamItems = [
    {
        img: `${process.env.PUBLIC_URL}/images/section6/team_icon_01.png`,
        name: "GIYOM",
        position: "Non-fungible Wizard",
        description: "Legendary Starcraft Player. NFT and P2E Ambassador.",
    },
    {
        img: `${process.env.PUBLIC_URL}/images/section6/team_icon_02.png`,
        name: "OCEAN",
        position: "CEO",
        description:
            "Has founded several global companies over the past 20 years and has also acted as a Financial Consultant for a number of international businesses.",
    },
    {
        img: `${process.env.PUBLIC_URL}/images/section6/team_icon_03.png`,
        name: "JUPITER",
        position: "Co-CEO",
        description:
            "Serial global business creator and management consultant. Has been building and executing business strategies for over 15 years.",
    },
    {
        img: `${process.env.PUBLIC_URL}/images/section6/team_icon_04.png`,
        name: "JAMES J.",
        position: "CTO",
        description:
            "Launched one of the first NFT games in 2018. Has been developing mobile games for 13 years.",
    },
    {
        img: `${process.env.PUBLIC_URL}/images/section6/team_icon_05.png`,
        name: "SAM G.",
        position: "Chief Creative Officer",
        description:
            "Prof. at ArtCenter college of design in California. Over 25 years of experience as an artist with Sony and Disney.",
    },
];
