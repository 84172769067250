import { createTheme } from "@mui/material";
import { lightPalette } from "./Color";
import { ScreenType } from "./Limit";
import { lightTypographyOptions } from "./Typography";

export const defaultTheme = createTheme({
    palette: lightPalette,
    typography: lightTypographyOptions,
    breakpoints: {
        values: {
            xs: 0,
            sm: ScreenType.small + 1,
            md: ScreenType.medium + 1,
            lg: ScreenType.large + 1,
            xl: ScreenType.tablet + 1,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                },
                sizeLarge: {
                    height: "60px",
                },
            },
        },
    },
});
