import styled from "@emotion/styled";
import { Stack, Typography, TypographyProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Video } from "../../../components/media/video/Video";
import { HighlightTypo } from "../../../components/typography/HighlightTypo";
import { DescriptionBodyStyle } from "../../../themes/global-styles/DescriptionBody.style";
import { ScreenType } from "../../../themes/Limit";

export const Section3 = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <Inner>
                <Stack aria-label="section1">
                    <Section1TitleTypo variant="h2">
                        <span>{t("section3:section1Title1_1")}</span>
                        <HighlightTypo variant="secondary">
                            {t("section3:section1Title1_2")}
                        </HighlightTypo>
                    </Section1TitleTypo>

                    <DescriptionBody textAlign={"center"} mt={"24px"}>
                        <SectionDescription>
                            {t("section3:section1DescriptionBody1_1")}
                        </SectionDescription>
                        <SectionDescription>
                            {t("section3:section1DescriptionBody1_2")}
                        </SectionDescription>
                    </DescriptionBody>
                </Stack>

                <Section2Container>
                    <Stack>
                        <SectionTitleTypo>
                            <span>{t("section3:section2Title1_1")}</span>
                            <HighlightTypo variant="primary">
                                {t("section3:section2Title1_2")}
                            </HighlightTypo>
                        </SectionTitleTypo>

                        <DescriptionBody mt={"24px"}>
                            <SectionDescription>
                                <span>
                                    {t("section3:section2DescriptionBody1_1")}
                                </span>
                                <span>
                                    {t("section3:section2DescriptionBody1_2")}
                                </span>
                                <span>
                                    {t("section3:section2DescriptionBody1_3")}
                                </span>
                            </SectionDescription>
                        </DescriptionBody>
                    </Stack>
                    <VideoWrapper>
                        <Video
                            src={`${process.env.PUBLIC_URL}/images/section3/section3_video-1.mp4#t=2`}
                            preload="metadata"
                            controls
                        />
                    </VideoWrapper>
                </Section2Container>

                <Section3Container>
                    <Stack>
                        <SectionTitleTypo>
                            <span>{t("section3:section3Title1_1")}</span>
                            <HighlightTypo variant="primary">
                                {t("section3:section3Title1_2")}
                            </HighlightTypo>
                        </SectionTitleTypo>

                        <DescriptionBody mt={"24px"}>
                            <SectionDescription>
                                <span>
                                    {t("section3:section3DescriptionBody1_1")}
                                </span>
                                <span>
                                    {t("section3:section3DescriptionBody1_2")}
                                </span>
                            </SectionDescription>
                            <SectionDescription mt={"24px"}>
                                <span>
                                    {t("section3:section3DescriptionBody2_1")}
                                </span>
                                <span>
                                    {t("section3:section3DescriptionBody2_2")}
                                </span>
                                <span>
                                    {t("section3:section3DescriptionBody2_3")}
                                </span>
                            </SectionDescription>
                        </DescriptionBody>
                    </Stack>
                    <VideoWrapper>
                        <Video
                            src={`${process.env.PUBLIC_URL}/images/section3/section3_video-2.mp4#t=0.1`}
                            preload="metadata"
                            controls
                        />
                    </VideoWrapper>
                </Section3Container>
            </Inner>
        </Root>
    );
};

//
const Root = styled("article")`
    display: flex;
    flex-direction: column;
    position: relative;

    background-color: #151a2f;
`;

const Inner = styled(Stack)`
    padding: 100px 24px;
    align-items: center;
`;

// video
const VideoWrapper = styled(Stack)`
    position: relative;
    aspect-ratio: 4/3;
    width: 100%;
    max-width: 600px;
`;

// text
const DescriptionBody = styled(Stack)`
    ${DescriptionBodyStyle}
`;

// sections
const SectionTitleTypo = (props: TypographyProps) => (
    <Typography
        variant="h4"
        display={"flex"}
        flexDirection={"column"}
        {...props}
    />
);

const SectionDescription = (props: TypographyProps) => (
    <Typography
        variant="body2"
        display={"flex"}
        flexDirection={"column"}
        {...props}
    />
);

const Section1TitleTypo = styled(Typography)`
    text-align: center;

    @media (max-width: ${ScreenType.tablet}px) {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: ${ScreenType.medium}px) {
        font-size: 32px;
    }
`;

const Section2Container = styled(Stack)`
    margin-top: 80px;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: center;
    gap: 24px;

    @media (max-width: ${ScreenType.tablet}px) {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
`;

const Section3Container = styled(Stack)`
    margin-top: 80px;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 24px;
    text-align: end;

    @media (max-width: ${ScreenType.tablet}px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
`;
