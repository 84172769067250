import styled from "@emotion/styled";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SectionRoot } from "../../../components/base/SectionRoot";
import { Image } from "../../../components/media/images/Image";
import { DescriptionBodyStyle } from "../../../themes/global-styles/DescriptionBody.style";
import { ScreenType } from "../../../themes/Limit";
import { links as _links } from "../../../../domain/Links";

export const Section1 = () => {
    const { t } = useTranslation();

    const handleButtonClick = () => {
        window.open(_links[0].path);
    };

    return (
        <SectionRoot>
            <BGWrapper>
                <VideoBG
                    src={`${process.env.PUBLIC_URL}/images/section1/section1_video.mp4`}
                    autoPlay muted loop
                />
            </BGWrapper>

            <ContentContainer>
                <LogoWrapper>
                    <Image
                        src={`${process.env.PUBLIC_URL}/images/common/logo_vertical.png`}
                        alt="logo"
                        style={{ objectFit: "fill" }}
                    />
                </LogoWrapper>

                <DescriptionContainer>
                    <DescriptionTitle variant="h3">
                        {t("section1:descriptionTitle")}
                    </DescriptionTitle>

                    <DescriptionBody variant="body2">
                        <span>{t("section1:descriptionBody1")}</span>
                        <span>{t("section1:descriptionBody2")}</span>
                        <span>{t("section1:descriptionBody3")}</span>
                    </DescriptionBody>
                </DescriptionContainer>

                <StyledButton
                    variant="contained"
                    size="large"
                    onClick={handleButtonClick}
                >
                    <Typography variant="h6">
                        {t("section1:buttonText")}
                    </Typography>
                </StyledButton>
            </ContentContainer>
        </SectionRoot>
    );
};

// BG
const BGWrapper = styled(Stack)`
    background-color: #000;
    width: 100%;
    height: 100%;
`;

const VideoBG = styled("video")`
    object-fit: cover;
    opacity: 0.6;
    height: 100%;
`;

const StyledBG = styled(Image)`
    object-fit: cover;
    opacity: 0.6;
`;

// Contents
const ContentContainer = styled(Stack)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px 60px 24px;
`;

const LogoWrapper = styled(Stack)`
    width: 100%;
    max-width: 600px;

    @media (max-width: ${ScreenType.medium}px) {
        max-width: 520px;
    }
`;

const DescriptionContainer = styled(Stack)`
    width: 100%;
    text-align: center;
    align-items: center;
    margin-top: 72px;

    @media (max-width: ${ScreenType.medium}px) {
        margin-top: 48px;
    }
`;

const DescriptionTitle = styled(Typography)`
    width: 100%;

    @media (max-width: ${ScreenType.medium}px) {
        font-size: 32px;
    }
`;

const DescriptionBody = styled(Typography)`
    margin-top: 24px;
    ${DescriptionBodyStyle}
`;

// Button
const StyledButton = styled(Button)`
    width: 280px;
    margin-top: 32px;

    white-space: nowrap;
`;
