import { PaletteOptions } from "@mui/material";

/**
 * Light Theme Color
 * */

export const LIGHT_PRIMARY_COLOR = "#4aacb5";
export const LIGHT_PRIMARY_BRIGHT_COLOR = "#69f2ff";
export const LIGHT_SECONDARY_COLOR = "#ff1dba";
export const DANGER_COLOR = "#E74C3C";

export const lightPalette: PaletteOptions = {
    primary: {
        main: LIGHT_PRIMARY_COLOR,
        A400: LIGHT_PRIMARY_BRIGHT_COLOR,
    },
    secondary: {
        main: LIGHT_SECONDARY_COLOR,
    },
    background: {
        default: "#fafafa",
    },
    warning: {
        main: DANGER_COLOR,
    },
};
