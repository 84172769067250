import styled from "@emotion/styled";
import { Stack, Typography, TypographyProps } from "@mui/material";
import { Image } from "../../../../components/media/images/Image";
import { HighlightTypo } from "../../../../components/typography/HighlightTypo";
import { ScreenType } from "../../../../themes/Limit";
import { useCustomMediaQuery } from "../../../../themes/UseCustomMediaQuery";

export type TimeLineItemProps = {
    year?: string;
    marginBottom?: string;
    title: string;
    body: string[];
};

export const TimeLineItem = (props: TimeLineItemProps) => {
    const { year, marginBottom = "48px", title, body } = props;

    const { isSmall } = useCustomMediaQuery();

    return (
        <Root mb={marginBottom}>
            {year && !isSmall && <YearTypo>{year}</YearTypo>}

            <TimeLienDotWrapper>
                <Image
                    src={`${process.env.PUBLIC_URL}/images/section5/timeline_dot.svg`}
                    alt="timeline_dot"
                />
            </TimeLienDotWrapper>

            <DescriptionContainer>
                <Typography variant="h5">
                    <HighlightTypo variant="secondary">{title}</HighlightTypo>
                </Typography>

                <DescriptionBody>
                    {body.map((it, index) => (
                        <span key={index}>{it}</span>
                    ))}
                </DescriptionBody>
            </DescriptionContainer>
        </Root>
    );
};

//

const Root = styled(Stack)`
    position: relative;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;

const TimeLienDotWrapper = styled(Stack)`
    width: 36px;
    height: 36px;
    justify-content: center;
`;

const DescriptionContainer = styled(Stack)`
    width: 210px;
    margin-top: 2px;
    margin-left: 80px;

    @media (max-width: ${ScreenType.medium}px) {
        margin-left: 24px;
    }
`;

const DescriptionBody = (props: TypographyProps) => {
    const { isSmall } = useCustomMediaQuery();

    return (
        <Typography
            variant="body2"
            display={"flex"}
            flexDirection={"column"}
            mt={"12px"}
            ml={"24px"}
            whiteSpace={isSmall ? "pre-wrap" : "nowrap"}
            {...props}
        />
    );
};

const YearTypo = (props: TypographyProps) => {
    const { isMedium } = useCustomMediaQuery();
    return (
        <Typography
            variant="h4"
            position={"absolute"}
            pr={isMedium ? "444px" : "500px"}
            {...props}
        />
    );
};
