import { i18nResource } from "../../i18nResource.interface";

const section3: i18nResource.Section3 = {
    section1Title1_1: "OUR ",
    section1Title1_2: "METAVERSE",
    section1DescriptionBody1_1:
        "Fight against viruses attacking us in cyberspace, defend the city against a robot zombie apocalypse,",
    section1DescriptionBody1_2:
        "or become a K-POP star and perform on the world's biggest stage in our Metaverse.",
    section2Title1_1: "WARP INTO",
    section2Title1_2: "NEW WORLDS",
    section2DescriptionBody1_1:
        "META ADVENTURE takes you to a new future world",
    section2DescriptionBody1_2:
        "where different species, planets,",
    section2DescriptionBody1_3: "and space time continuums co-exist.",
    section3Title1_1: "IMMERSIVE",
    section3Title1_2: "P2E GAMES",
    section3DescriptionBody1_1:
        "Our vision is to release entertaining P2E games",
    section3DescriptionBody1_2:
        "with high-quality art and an amazing gaming experience.",
    section3DescriptionBody2_1:
        "Earn rewards that allow you to compete ",
    section3DescriptionBody2_2:
        "for the leaderboard and grab unique rewards.",
    section3DescriptionBody2_3: "Enjoy infinite growth of your characters.",
};

export default section3;
