import { i18nResource } from "../../i18nResource.interface";

const header: i18nResource.Header = {
    greeting: "안녕하세요",
    home: "홈",
    nft: "NFT",
    metaVerse: "메타버스",
    gallery: "갤러리",
    roadmap: "로드맵",
    team: "팀",
    partners: "파트너",
    community: "커뮤니티",
};

export default header;
