import { Stack } from "@mui/material";
import { GlobalNavigationBar } from "../../ui/domains/home/GNB/GlobalNavigationBar";
import { Section1 } from "../../ui/domains/home/Section1/Section1";
import { Section2 } from "../../ui/domains/home/Section2/Section2";
import { Section3 } from "../../ui/domains/home/Section3/Section3";
import { Section4 } from "../../ui/domains/home/Section4/Section4";
import { Section5 } from "../../ui/domains/home/Section5/Section5";
import { Section6 } from "../../ui/domains/home/Section6/Section6";
import { Section7 } from "../../ui/domains/home/Section7/Section7";
import { useScrollRef } from "../../ui/hooks/useScrollRef";
import { useCustomMediaQuery } from "../../ui/themes/UseCustomMediaQuery";
import { links as _links } from "../../domain/Links";

const HomePage = () => {
    const { isMedium } = useCustomMediaQuery();
    const { refList, scrollTo } = useScrollRef(7, {
        marginTop: isMedium ? 54 : 64,
    });

    const handleMenuClick = (index: number) => {
        if(index != 6)
            scrollTo(index);
        else
            window.open(_links[1].path);
    };

    return (
        <Stack>
            <GlobalNavigationBar onMenuClick={handleMenuClick} />
            <Stack ref={refList[0]}>
                <Section1 />
            </Stack>
            <Stack ref={refList[1]}>
                <Section2 />
            </Stack>
            <Stack ref={refList[2]}>
                <Section3 />
            </Stack>
            <Stack ref={refList[3]}>
                <Section4 />
            </Stack>
            <Stack ref={refList[4]}>
                <Section5 />
            </Stack>
            <Stack ref={refList[5]}>
                <Section6 />
            </Stack>
        </Stack>
    );
};

export default HomePage;
