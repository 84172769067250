import { i18nResource } from "../../i18nResource.interface";

const header: i18nResource.Header = {
    greeting: "hello",
    home: "HOME",
    nft: "NFT",
    metaVerse: "METAVERSE",
    gallery: "GALLERY",
    roadmap: "ROADMAP",
    team: "TEAM",
    partners: "PARTNERS",
    community: "COMMUNITY",
};

export default header;
