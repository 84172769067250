import { links as _links } from "../../../../domain/Links";

export const useLinkPopup = () => {
    const links = _links.map((it) => it.name);
    const handleLinkClick = (linkName: string) => {
        const target = _links.find((it) => it.name === linkName);
        window.open(target?.path);
    };

    return {
        links,
        handleLinkClick,
    };
};
